import './styles.scss';

import React, { HTMLAttributes, ReactNode } from 'react';

import { Alert } from 'react-bootstrap';
import CatchError from '@/components/CatchError';
import { getClasses } from '@/utils';

export type FieldProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  label?: string;
  feedback?: string;
  valid?: boolean;
  dirty?: boolean;
  required?: boolean;
  children: ReactNode;
};

const Field = ({ label, feedback, valid, dirty, required, children, ...props }: FieldProps): ReactNode => (
  <div
    {...props}
    className={getClasses(
      'Field',
      props?.className,
      typeof valid === 'boolean' ? (valid ? 'is-valid' : 'is-invalid') : undefined,
      dirty ? 'is-dirty' : undefined
    )}
  >
    {label && (
      <small className="Field-Label">
        <span>{label}</span>
        {required !== undefined && required !== false && <span className="Field-Required">*</span>}
      </small>
    )}
    <CatchError fallback={(): ReactNode => <Alert variant="danger">Something went wrong.</Alert>}>{children}</CatchError>
    {feedback && <small className="Field-Feedback">{feedback}</small>}
  </div>
);

export default Field;
