import { ImportStatusEnum, TripCombineTypeEnum } from '@/models/gen/graphql';

import { MenuItem } from './components/MenuDrawer';
import { Route } from '@/models';
import dayjs from 'dayjs';

export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const DATE_FE_FORMAT = 'MM/DD/YY';
export const DATE_FE_FORMAT_FULL = 'MM/DD/YYYY';
export const DATE_FE_FORMAT_SHORT = 'MM/DD';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_FULL = 'HH:mm:ss';
export const DATETIME_FORMAT = 'MM-DD-YYYY HH:mm';
export const DATETIME_INPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FE_FORMAT_FULL = 'MM/DD/YYYY - HH:mm:ss';
export const DATETIME_CHAT_FORMAT = 'MM-DD-YY HH:mm';
export const DATETIME_RFC_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATETIME_FE_FORMAT = `${DATE_FE_FORMAT} ${TIME_FORMAT}`;
export const DATETIME_FE_FORMAT_SHORT = `${DATE_FE_FORMAT_SHORT} ${TIME_FORMAT}`;
export const DATE_UNIT_ENUM = ['years', 'months', 'week', 'days', 'hours', 'minutes', 'seconds'];
export const DAYS_OF_THE_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const DAY_MINUTES = 1440;
export const EOD_MINUTES = DAY_MINUTES - 1;
export const TIMEZONE_OFFSET = new Date().getTimezoneOffset();
export const CUSTOM_LABELS = {
  // ID is in here 3 times to cover different cases specifically, otherwise
  // words like 'Provider' would become 'ProvIDer'.
  // Making a more complex RegEx is possible, but this is just faster.
  '^id$': 'ID',
  'id ': 'ID ',
  ' id': ' ID',
  '^fcrs$': 'COMPLAINTS',
  'do location': 'D/O Location',
  'pu location': 'P/U Location',
};
export const INDEXED_DB_VERSION = 1;
export const WHITE_SPACES = [
  ' ',
  '\n',
  '\r',
  '\t',
  '\f',
  '\v',
  '\u00A0',
  '\u1680',
  '\u180E',
  '\u2000',
  '\u2001',
  '\u2002',
  '\u2003',
  '\u2004',
  '\u2005',
  '\u2006',
  '\u2007',
  '\u2008',
  '\u2009',
  '\u200A',
  '\u2028',
  '\u2029',
  '\u202F',
  '\u205F',
  '\u3000',
];

/**
 * @deprecated use ImportStatusEnum to reference import status
 */
export const IMPORT_STATUS = [
  'UPLOADED',
  'PARSED',
  'POTENTIAL',
  'STAGING',
  'STAGED',
  'INGESTING',
  'INGESTED',
  'ROLLING_BACK',
  'ROLLED_BACK',
  'FAILED',
].sort();
export const PENDING_STATUS = [
  ImportStatusEnum.Uploaded,
  ImportStatusEnum.Parsed,
  ImportStatusEnum.NotParsed,
  ImportStatusEnum.Potential,
  ImportStatusEnum.Staging,
  ImportStatusEnum.Staged,
  ImportStatusEnum.Ingesting,
].sort();
export const HISTORY_STATUS = [
  ImportStatusEnum.Ingested,
  ImportStatusEnum.RollingBack,
  ImportStatusEnum.RolledBack,
  ImportStatusEnum.Failed,
].sort();
export const POLLING_STATUS = [ImportStatusEnum.Uploaded, ImportStatusEnum.Staging, ImportStatusEnum.Ingesting].sort();
export const PREVIEW_STATUS = [ImportStatusEnum.Potential, ImportStatusEnum.Staged, ImportStatusEnum.NotParsed].sort();
export const OVERRIDE_STATUS = [ImportStatusEnum.Parsed].sort();

export const IMPORT_TYPES = ['TRAINING', 'MANIFEST', 'ADDON', 'DAILY'];
export const PAGE_SIZE = 30;
export const DISPLAY_SIZE = 50;
export const VEHICLE_MODEL_ENUM = ['nissan', 'ford'];
export const SOCKETS_ENDPOINT = 'https://chat.dev.skyvibe.io';
// export const SOCKETS_ENDPOINT = 'http://localhost:8000';
// export const VALID_UUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
export const VALID_UUID = /^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}$/i;
export const PROFILE_URL =
  'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80';
export const VALID_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_WEBSITE = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;
export const VALID_ALPHANUMERIC = new RegExp('^[^ ][0-9a-z ]', 'i');
export const VALID_ALPHA = new RegExp('^[^ ][a-z ]', 'i');
export const VALID_NUMERIC = new RegExp('^[^ ][0-9 ]', 'i');
export const VALID_TIME_STRING = /^([0-1]\d|2[0-3]):([0-5]\d)$/;
export const VALID_FULL_TIME_STRING = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
export const IS_NUMERIC = /^\d+$/i;
export const REFETCH_INTERVAL = 180000;
export const CURRENT_DATE = dayjs();
export const TODAY = CURRENT_DATE.clone().startOf('day').format(DATETIME_RFC_FORMAT);
export const TODAY_EOD = CURRENT_DATE.clone().endOf('day').format(DATETIME_RFC_FORMAT);
export const YESTERDAY = CURRENT_DATE.clone().subtract(1, 'days').startOf('day').format(DATETIME_RFC_FORMAT);
export const YESTERDAY_EOD = CURRENT_DATE.clone().subtract(1, 'days').endOf('day').format(DATETIME_RFC_FORMAT);
export const TOMORROW = CURRENT_DATE.clone().add(1, 'days').startOf('day').format(DATETIME_RFC_FORMAT);
export const TOMORROW_EOD = CURRENT_DATE.clone().add(1, 'days').endOf('day').format(DATETIME_RFC_FORMAT);
export const MONTH_START = CURRENT_DATE.clone().startOf('month').format(DATETIME_RFC_FORMAT);
export const MONTH_END = CURRENT_DATE.clone().endOf('month').format(DATETIME_RFC_FORMAT);
export const STATUSES = {
  default: [
    { id: 0, displayName: 'Inactive' },
    { id: 1, displayName: 'Active' },
  ],
  repeat: [
    { id: 0, displayName: 'No Repeat' },
    { id: 1, displayName: 'Repeat' },
  ],
};
export const REDUCER_KEY = {
  APP: 'persist:app',
  NOTIFICATIONS: 'persist:notifications',
};
export const DATA_CY_FORMAT = '{{name}}-{{selector}}';
export const NOTIFICATION_TIMEOUT = 8000;
export const MAX_PROFILE_IMAGE_SIZE_MB = 3;
export const FLAG_DISPLAY_MAX_COUNT = 3;
export const ROUTES: Route[] | Partial<MenuItem>[] = [
  {
    url: '/trips',
    label: 'Trips',
    icon: <i className="sv sv-earth" />,
  },
  {
    url: '/manifests',
    label: 'Manifests',
    icon: <i className="sv sv-document" />,
    search: '?type=MANIFEST',
  },
  {
    url: '/manifests',
    search: '?type=ADDON',
    label: 'Add-ons',
    icon: <i className="sv sv-folder-plus" />,
  },
  {
    url: '/imports/history',
    label: 'Manifest \nHistory',
    icon: <i className="sv sv-calendar-31" />,
  },
  {
    url: '/availability',
    label: 'Availability',
    icon: <i className="sv sv-calendar-full" />,
  },
  { url: '/locations', label: 'Locations', icon: <i className="sv sv-network" /> },
  {
    url: '/message-center',
    label: 'Message Center',
    icon: <i className="sv sv-bubbles" />,
  },
  {
    url: '/invoices/0',
    label: 'Invoice',
    icon: <i className="sv sv-receipt" />,
  },
  {
    url: '/invoices',
    label: 'Invoice \nHistory',
    icon: <i className="sv sv-invoice-history" />,
  },
  { url: '/rates', label: 'Rates', icon: <i className="sv sv-tags" /> },
  { url: '/reports', label: 'Reports', icon: <i className="sv sv-chart-growth" /> },
  {
    url: '/contract-rates',
    label: 'Contract \nRates',
    icon: <i className="sv sv-tags" />,
  },
  { url: '/users', label: 'Users', icon: <i className="sv sv-users2" /> },
  { url: '/airlines', label: 'Airlines', icon: <i className="sv sv-plane" /> },
  { url: '/clients', label: 'Clients', icon: <i className="sv sv-profile" /> },
  { url: '/vehicles', label: 'Vehicles', icon: <i className="sv sv-car" /> },
  { url: '/permissions', label: 'Permissions', icon: <i className="sv sv-user-lock" /> },
  { url: '/complaints', label: 'Complaints', icon: <i className="sv sv-fcr" /> },
  { url: '/ratings', label: 'Ratings', icon: <i className="sv sv-star" /> },
  { url: '/punch-policy', label: 'Punch Policy', icon: <i className="sv sv-watch" />, permissions: ['allowCustomizeReports'] },
  {
    url: '/report',
    label: 'Report A \nProblem',
    icon: <i className="sv sv-shield-alert" />,
  },
];
const PRIORITY_ROUTES = ['/trips', '/manifests', '/availability', '/message-center', '/reports'];
export const QUICK_ROUTES = ROUTES.filter((route) => PRIORITY_ROUTES.includes(route.url) && route?.search !== '?type=MANIFEST').sort(
  (a: Route, b: Route): number =>
    PRIORITY_ROUTES.findIndex((url: string): boolean => a.url.startsWith(url)) -
    PRIORITY_ROUTES.findIndex((url: string): boolean => b.url.startsWith(url))
);
export const ILLEGAL_COMBINES = [TripCombineTypeEnum.Illegal, TripCombineTypeEnum.IllegalOverCapacity];
