import { InitComponentStateType, createComponentState } from '@/state';
import { SortDirectionEnum, Trip } from '@/models/gen/graphql';

import { Datetime } from '@/utils/dates';

export enum TripRowActionEnum {
  UPDATE = 'UPDATE', // update rows but don't persist state
  UPDATE_AND_COMBINE = 'UPDATE_AND_COMBINE', // persist state
}
// this enum maps the sortable fields in the TripTableSearch type
export enum TripSortColumnEnum {
  type = 'type',
  latestScheduled = 'latestScheduled',
  latestScheduledUtc = 'latestScheduledUtc',
  actual = 'actual',
  airportCode = 'airportCode',
  servicerIataAirlineCode = 'servicerIataAirlineCode',
  flightNumber = 'flightNumber',
  loopName = 'loopName',
  pilots = 'pilots',
  attendants = 'attendants',
  driverId = 'driverId',
  driverFirstName = 'driverFirstName',
  driverLastName = 'driverLastName',
  vehicleId = 'vehicleId',
  vehicleTrackingId = 'vehicleTrackingId',
  puLocationId = 'puLocationId',
  puLocationName = 'puLocationName',
  doLocationId = 'doLocationId',
  doLocationName = 'doLocationName',
  payerProviderId = 'payerProviderId',
  payerProviderDisplayName = 'payerProviderDisplayName',
  rateAmount = 'rateAmount',
}

export type TripTableState = {
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>;
  onSortOnly: (column: TripSortColumnEnum[]) => void;
  onSort: (column: TripSortColumnEnum[]) => void;
  trips: Map<string, Trip>;
  onSetTrips: (rows: Trip[]) => void;
  refetch: () => void;
  selected: Map<string, Trip>;
  onSelect: (rowId: string) => void;
  onSelectOnly: (rowId: string) => void;
  onSetRow: (update: Partial<Trip>, tripIds: string[], action?: TripRowActionEnum) => void;
  onDeleteRow: (tripIds: Set<string>, softDelete?: boolean) => void;
  onUndeleteRow: (tripIds: Set<string>) => void;
};

export const DEFAULT_TRIP_SORTING = [{ column: TripSortColumnEnum.latestScheduledUtc, direction: SortDirectionEnum.Asc }];
const getNextSortDirection = (current: SortDirectionEnum): SortDirectionEnum => {
  switch (current) {
    case null:
      return SortDirectionEnum.Asc;
    case SortDirectionEnum.Asc:
      return SortDirectionEnum.Desc;
    case SortDirectionEnum.Desc:
    default:
      return null;
  }
};
const initTripTableState: InitComponentStateType<TripTableState> = (set, get): TripTableState => {
  return {
    trips: new Map(),
    sorting: DEFAULT_TRIP_SORTING,
    onSortOnly: (columns: TripSortColumnEnum[]): void => {
      // only sort columns provided
      set((current: TripTableState): TripTableState => {
        const found = current.sorting.find((c) => c.column === columns[0]);
        const next: SortDirectionEnum | null = found ? getNextSortDirection(found.direction) : SortDirectionEnum.Asc;
        // if next is null reset sorting to default
        if (next === null) return { ...current, sorting: DEFAULT_TRIP_SORTING };
        return { ...current, sorting: columns.map((column) => ({ column, direction: next })) };
      });
    },
    onSort: (columns: TripSortColumnEnum[]): void => {
      set((current: TripTableState): TripTableState => {
        const found = current.sorting.find((s) => s.column === columns[0]);
        // add columns to sort
        const columnsMap = columns.reduce((acc, column) => ({ ...acc, [column]: true }), {});
        if (!found) {
          return { ...current, sorting: [...current.sorting, ...columns.map((column) => ({ column, direction: SortDirectionEnum.Asc }))] };
        }
        const next = getNextSortDirection(found.direction);
        if (next !== null) {
          // update with next sort direction
          return { ...current, sorting: current.sorting.map((s) => (columnsMap[s.column] ? { ...s, direction: next } : s)) };
        }
        // remove from array if next is null
        const filtered = current.sorting.filter((s) => !columnsMap[s.column]);
        return { ...current, sorting: filtered.length ? filtered : DEFAULT_TRIP_SORTING };
      });
    },
    refetch: () => {
      console.log('default refetch called');
      return null;
    },
    selected: new Map(),
    onSelect: (rowId: string): void => {
      set((current: TripTableState): TripTableState => {
        const selected = new Map(current.selected);
        const found = selected.get(rowId);

        // add selection
        if (!found) selected.set(rowId, current.trips.get(rowId));
        else selected.delete(rowId); // remove selection

        // update state
        return { ...current, selected };
      });
    },
    onSelectOnly: (rowId: string): void => {
      set((current: TripTableState): TripTableState => {
        const selected = new Map();
        // add selection
        selected.set(rowId, current.trips.get(rowId));
        // update state
        return { ...current, selected };
      });
    },
    onSetRow: (update: Partial<Trip>, tripIds: string[], action: TripRowActionEnum = TripRowActionEnum.UPDATE): void => {
      set((current: TripTableState): TripTableState => {
        const combinedTripIds: string[] = (tripIds !== undefined ? tripIds : current.trips.get(update.id)?.[action]) || [];

        const trips = new Map(current?.trips);
        const idsToUpdate = new Set([update.id, ...combinedTripIds]);

        let updated = 0;
        // UPDATE_AND_COMBINE add combine key/property to update to persist through component lifecycle
        if (action === TripRowActionEnum.UPDATE_AND_COMBINE && tripIds !== undefined) update[action] = Array.from(idsToUpdate);
        //TODO: add un-combine blacklist keys

        // Iterate over rows only once
        for (const rowId of trips.keys()) {
          // Skip rows that are not in idsToUpdate
          if (!idsToUpdate.has(rowId)) continue;
          // Update the row
          const row = trips.get(rowId);
          const result = { ...row, ...update, id: rowId };
          trips.set(rowId, result);
          updated++;
          // Break the loop if all necessary updates have been made
          if (updated === idsToUpdate?.size) break;
        }

        return { ...current, trips };
      });
    },
    onDeleteRow: (tripIds: Set<string>, softDelete?: boolean): void => {
      set((current: TripTableState): TripTableState => {
        const trips = new Map(current.trips);
        let updated = 0;
        const deletedAt = softDelete ? new Datetime().toString() : null;
        for (const rowId of trips.keys()) {
          if (!tripIds.has(rowId)) continue;
          // if the trip is in all or deleted format, update the deletedAt property to now
          const row = trips.get(rowId);
          if (softDelete) trips.set(rowId, { ...row, deletedAt });
          else trips.delete(rowId); // otherwise remove the trip from state
          updated++;
          if (updated === tripIds.size) break;
        }
        return { ...current, selected: new Map(), trips };
      });
    },
    onUndeleteRow: (tripIds: Set<string>): void => {
      set((current: TripTableState): TripTableState => {
        const trips = new Map(current.trips);
        let updated = 0;

        for (const rowId of trips.keys()) {
          if (!tripIds.has(rowId)) continue;

          const row = trips.get(rowId);
          if (row?.deletedAt) {
            trips.set(rowId, { ...row, deletedAt: null });
            updated++;
          }

          if (updated === tripIds.size) break;
        }

        return {
          ...current,
          trips,
        };
      });
    },
    onSetTrips: (rows: Trip[]): void => {
      set((current) => {
        // clone trips
        const trips = new Map();
        // Directly update the existing Map with new rows
        rows.forEach((curr): void => {
          trips.set(curr.id, curr);
        });

        return {
          ...current,
          trips,
        };
      });
    },
  };
};
const useTripTableState = createComponentState(initTripTableState);

export const getSelectedTrips = (): Trip[] => {
  const output = [];
  const current = useTripTableState.getState().state;
  for (const tripId of current.selected.keys()) {
    output.push(current.trips.get(tripId));
  }
  return output;
};

export default useTripTableState;
