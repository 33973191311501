import { useCallback, useMemo } from 'react';

import FormButton from '@/components/FormButton';
import HasPermission from '@/components/HasPermission';
import useConfirmation from '@/hooks/useConfirmation';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';

type TripsTableShortcutsProps = {
  deleteAll: () => void;
  uaCancels: () => void;
  zeroRates: () => void;
  undeleteTrips: (tripIds: string[]) => void;
};
const TripsTableShortcuts = ({ deleteAll, uaCancels, zeroRates, undeleteTrips }: TripsTableShortcutsProps): React.JSX.Element => {
  const selectedTrips = useTripTableState(({ state }) => state.selected);

  const selectedDeletedTrips = useMemo((): string[] => {
    if (!selectedTrips?.size) return [];
    return [...selectedTrips.keys()].filter((id: string): boolean => !!selectedTrips.get(id)?.deletedAt);
  }, [selectedTrips]);

  const confirmUndelete = useConfirmation({ Body: { message: 'Are you sure you want to un-delete the selected trip(s)?' } });

  const handleUndeleteTrips = useCallback(async (): Promise<void> => {
    try {
      await confirmUndelete();
      undeleteTrips(selectedDeletedTrips);
    } catch (error) {
      console.error(error);
    }
  }, [confirmUndelete, selectedDeletedTrips, undeleteTrips]);

  return (
    <>
      <HasPermission name="allowViewTripShortcuts">
        <FormButton variant="icon" onClick={deleteAll} icon={<i className="sv sv-trash2" />} feedback="Delete All">
          Delete All
        </FormButton>
        <FormButton variant="icon" className="hide-icon" onClick={uaCancels} icon={<i className="fa fa-cancel" />} feedback="UA Cancels">
          UA Cancels
        </FormButton>
        <FormButton variant="icon" className="hide-icon" onClick={zeroRates} icon={<i className="fa fa-0" />} feedback="Zero Rates">
          Zero Rates
        </FormButton>
      </HasPermission>
      <HasPermission name="allowUndeleteTrip">
        {selectedDeletedTrips.length > 0 && (
          <FormButton variant="icon" onClick={handleUndeleteTrips} icon={<i className="sv sv-undo2" />} feedback="Undelete Trips">
            Undelete Trips
          </FormButton>
        )}
      </HasPermission>
    </>
  );
};

export default TripsTableShortcuts;
